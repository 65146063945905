<template>
  <div
    class="absolute hover:opacity-100 inset-0"
    :class="{
      'opacity-100': active,
      'md:opacity-0': !active,
      'hover:bg-black/20': !selected,
      'bg-white/20': selected,
    }"
  >
    <div class="absolute left-2 top-2">
      <div
        v-if="selectable"
        @click="select"
        size="30"
        class="cursor-pointer h-20px w-20px border rounded-md flex-align justify-center"
        :class="{
          'bg-white': !selected,
          'bg-black': selected,
        }"
      >
        <v-icon v-if="selected" size="18">check</v-icon>
      </div>
      <v-btn
        v-else
        @click="remove"
        icon="delete_outline"
        size="30"
        color="red"
      />
    </div>
    <div class="absolute left-2 bottom-2">
      <v-btn @click="edit" icon="edit" size="30" border />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    selected: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },
  methods: {
    select() {
      this.$emit("select");
    },
    edit() {
      this.$emit("edit");
    },
    remove() {
      this.$emit("remove");
    },
  },
});
</script>
