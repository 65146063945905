import { isEmpty } from "lodash";
import MediaInput from "./MediaInput.vue";

export default defineComponent({
  name: "SaveContextMedia",
  props: {
    name: { type: String, required: true },
    accept: { type: String },
    multiple: { type: Boolean, default: false },
    aspectRatio: { type: [String, Number] },
    maxSize: { type: [String, Number] },
    allowLink: { type: Boolean, default: false },
    allowLibrary: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const { mediaField } = useSaveContext();
    const field = mediaField(props.name, {
      accept: props.accept,
      multiple: props.multiple,
      aspectRatio: withValue(props.aspectRatio, (v) => Number(v)),
      maxSize: withValue(props.maxSize, (v) => Number(v)),
    });

    return () => {
      return h(
        MediaInput,
        {
          field,
          multiple: props.multiple,
          aspectRatio: props.aspectRatio,
          allowLink: props.allowLink,
          allowLibrary: props.allowLibrary,
        },
        ctx.slots
      );
    };
  },
});
