<template>
  <MediaEditorDialog v-for="entry in entries" :key="entry.id" :crop="entry" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useCropStore } from "../private/media-input/cropper";
import MediaEditorDialog from "../private/media-input/MediaEditorDialog.vue";

export default defineComponent({
  name: "MediaEditor",
  components: { MediaEditorDialog },
  setup() {
    const { entries } = useCropStore();

    return {
      entries,
    };
  },
});
</script>
