<template>
  <div
    class="media-picker view-border flex-center flex-col gap-1 cursor-pointer hover:bg-gray/10"
    :class="{ 'pa-2 aspect-1': mini, 'pa-10': !mini, mini }"
  >
    <div class="flex-align gap-2" :class="{ 'flex-col': mini }">
      <v-btn @click="open" class="text-none" elevation="1">Upload new</v-btn>
      <v-btn v-if="allowLibrary" class="text-none" variant="text" disabled>
        Use existing
      </v-btn>
      <v-btn
        @click="addFormUrl"
        v-else-if="allowLink"
        class="text-none"
        variant="text"
      >
        Add from URL
      </v-btn>
    </div>
    <div v-if="!mini && size" class="caption">
      Maximum file size: {{ sizeDisplay }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useFileDialog } from "@vueuse/core";
import { Rule } from "~/services/Rules";

export default defineComponent({
  props: {
    field: { type: Object as PropType<MediaUploadField>, required: true },
    multiple: { type: Boolean, default: false },
    aspect: { type: [String, Number] },
    size: { type: [String, Number] },
    allowLink: { type: Boolean, default: false },
    allowLibrary: { type: Boolean, default: false },
    mini: { type: Boolean, default: false },
  },
  setup(props) {
    const { wrapConfirmInput } = useConfirm();
    const { open, reset, onChange } = useFileDialog({
      accept: props.field.accept,
      multiple: props.multiple,
    });

    onChange((files) => {
      /** do something with files */
      if (files && props.multiple) {
        const f = files as any as File[];
        for (const file of f) {
          props.field.setUpload(file);
        }
      } else if (files) {
        const f = files[0] as File;
        props.field.setUpload(f);
      }
    });

    const addFormUrl = wrapConfirmInput(
      (data) => props.field.fromUrl(data.url),
      {
        title: "Add media from URL",
        doneText: "Add file",
        inputs: defineFormInputs({
          url: {
            type: "text",
            rules: Rule.group([Rule.required(), Rule.url()]),
            props: {
              placeholder: "https://",
            },
          },
        }),
      }
    );

    const sizeDisplay = computed(
      () => formatVolumeAmount(Number(props.size)) + "B"
    );

    return {
      sizeDisplay,
      open,
      addFormUrl,
    };
  },
});
</script>
