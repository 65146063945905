<template>
  <div>
    <div v-if="$slots.top" class="mb-2 flex-align">
      <template v-if="selectionCount">
        <span class="text-subtitle-2">{{ selectionCount }} selected</span>
        <v-spacer />
        <v-btn
          @click="removeSelected"
          variant="text"
          class="text-none"
          size="x-small"
          >Remove</v-btn
        >
      </template>
      <slot v-else name="top"></slot>
    </div>
    <div
      class="media-input grid grid-cols-2 md:grid-cols-4 gap-2"
      :class="{ empty }"
    >
      <MediaField
        v-for="(item, i) in media"
        :key="i"
        :media="item"
        :aspect-ratio="aspectRatio"
      >
        <MediaScrim
          v-if="!readonly"
          @select="toggleSelect(item)"
          @edit="field.edit(item)"
          @remove="field.remove(item)"
          :selected="item.selected"
          :selectable="multiple"
          :active="hasSelection"
        />
      </MediaField>
      <div class="picker">
        <MediaPicker v-bind="$props" :mini="!empty" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MediaField from "../private/media-input/MediaField.vue";
import MediaPicker from "../private/media-input/MediaPicker.vue";
import MediaScrim from "../private/media-input/MediaScrim.vue";
import type { MediaFile } from "../modules/media-library/runtime/types";
import { getMediaFileId } from "../private/media-input/lib";

export default defineComponent({
  name: "MediaInput",
  components: { MediaField, MediaPicker, MediaScrim },
  props: {
    field: { type: Object as PropType<MediaUploadField>, required: true },
    readonly: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    aspectRatio: { type: [String, Number] },
    size: { type: [String, Number] },
    allowLink: { type: Boolean, default: false },
    allowLibrary: { type: Boolean, default: false },
  },
  setup(props) {
    const selection = ref<Record<string, boolean>>({});

    const items = computed((): MediaFile[] => {
      const m = props.field.media.value;
      return m && Array.isArray(m) ? m : m ? [m] : [];
    });

    const isSelected = (item: MediaFile) => {
      const id = getMediaFileId(item);
      return !!selection.value[id];
    };

    const media = computed(() => {
      return items.value.map((item) => ({
        ...item,
        selected: isSelected(item),
      }));
    });

    const hasSelection = computed(() => {
      return someTrue(Object.values(selection.value));
    });

    const selected = computed(() => {
      return Object.entries(selection.value)
        .filter(([_, v]) => v === true)
        .map(([n]) => n);
    });

    const selectionCount = computed(() => {
      return selected.value.length;
    });

    const toggleSelect = (item: MediaFile) => {
      const id = getMediaFileId(item);
      const selected = isSelected(item);
      selection.value[id] = !selected;
    };

    const removeSelected = () => {
      const s = toRaw(selected.value);
      for (const id of s) {
        props.field.remove(id);
      }
      selection.value = {};
    };

    return {
      media,
      hasSelection,
      selectionCount,
      toggleSelect,
      removeSelected,
    };
  },
  computed: {
    empty(): boolean {
      return !this.media.length;
    },
  },
});
</script>

<style lang="scss" scoped>
.media-field:first-child {
  @apply md:col-span-2 md:row-span-2;
}
.empty .picker {
  @apply col-span-4 row-span-4;
}
</style>
