<template>
  <v-dialog v-model="isOpen" max-width="700" persistent>
    <v-sheet width="100%" height="100%" rounded="lg">
      <div class="flex-align pl-4 py-2 pr-2">
        <h3 class="heading-2">Size image</h3>
        <v-spacer />
        <v-btn @click="close" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <div class="h-400px max-h-400px overflow-hidden">
        <Cropper
          v-if="img"
          ref="cropper"
          class="relative"
          :src="img"
          :stencil-props="{
            aspectRatio: crop.aspectRatio,
          }"
        />
      </div>
      <div class="flex-align px-4 py-2">
        <v-spacer />
        <v-btn @click="done" color="primary">Done</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { MediaCropperData } from "./cropper";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
  props: {
    crop: { type: Object as PropType<MediaCropperData>, required: true },
  },
  components: { Cropper },
  setup(props) {
    const isOpen = ref(true);
    const cropper = ref<InstanceType<typeof Cropper>>();

    const img = computed(() => getMediaUrl(props.crop.media));

    const done = () => {
      if (cropper.value) {
        const { canvas } = cropper.value.getResult();
        if (canvas) {
          canvas.toBlob((blob) => {
            isOpen.value = false;
            if (blob) {
              const file = blobToFile(blob, props.crop.media.name);
              props.crop.done(file);
            }
          });
        }
      }
    };

    const close = () => {
      isOpen.value = false;
      props.crop.cancel();
    };

    return {
      isOpen,
      cropper,
      img,
      done,
      close,
    };
  },
});
</script>
