<template>
  <div
    class="media-field border rounded-lg overflow-hidden flex justify-between items-center relative"
    :style="{ '--aspect': aspectRatio }"
  >
    <ImageField v-if="isImage" :image="media" />
    <div v-else class="pa-2 caption text-center">Unsupported media type</div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { MediaFile } from "../../modules/media-library/runtime/types";
import { MediaFileType } from "../../models";

export default defineComponent({
  props: {
    media: { type: Object as PropType<MediaFile>, required: true },
    aspectRatio: { type: [String, Number], default: 1 },
  },
  computed: {
    isImage() {
      return this.media.type === MediaFileType.IMAGE;
    },
  },
});
</script>

<style lang="scss" scoped>
.media-field {
  aspect-ratio: var(--aspect);
}
</style>
